import React from 'react';
import {
  hasGrantedAllScopesGoogle,
  useGoogleLogin,
} from '@react-oauth/google';
import {
  Button,
} from '@livechat/design-system'

import ViewContainer, { CenterContainer} from './ViewContainer';

const clientId = process.env.GOOGLE_OAUTH_CLIENT_ID;

interface SignInProps {
  setCredential(cred: string): any,
  addAlert(msg: string, variant: string): any,
}

function SignIn(props: SignInProps) {
  const {
    setCredential,
    addAlert,
  } = props;

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      console.log(tokenResponse);
      const hasAccess = hasGrantedAllScopesGoogle(
        tokenResponse,
        'https://www.googleapis.com/auth/calendar.readonly',
        'https://www.googleapis.com/auth/calendar.events',
      );
      if (hasAccess) {
        setCredential(tokenResponse.access_token);
      } else {
        addAlert('Proper authorization has not been granted.', 'error');
      }
    },
    scope: 'https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/calendar.events',
    // flow: 'auth-code',
  });

  if (clientId) {
    return (
      <CenterContainer>
        <Button onClick={() => login()}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <img
              src="/calendar.png"
              alt="Google Calendar logo"
              style={{height: '2em'}}
            />
            <p>&nbsp;Connect Google Calendar</p>
          </div>
        </Button>
      </CenterContainer>
    );
  }

  // This means the client ID was not loaded
  return (
    <ViewContainer>
      <h1>An error occurred. Please contact support.</h1>
    </ViewContainer>
  );
}

export default SignIn;
