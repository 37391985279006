const times = [
  {
    key: '12:00 Midnight',
    props: {
      id: '12:00 Midnight',
      hour: 0,
      minute: 0,
    },
  },
  {
    key: '12:30 AM',
    props: {
      id: '12:30 AM',
      hour: 0,
      minute: 30,
    },
  },
  {
    key: '1:00 AM',
    props: {
      id: '1:00 AM',
      hour: 1,
      minute: 0,
    },
  },
  {
    key: '1:30 AM',
    props: {
      id: '1:30 AM',
      hour: 1,
      minute: 30,
    },
  },
  {
    key: '2:00 AM',
    props: {
      id: '2:00 AM',
      hour: 2,
      minute: 0,
    },
  },
  {
    key: '2:30 AM',
    props: {
      id: '2:30 AM',
      hour: 2,
      minute: 30,
    },
  },
  {
    key: '3:00 AM',
    props: {
      id: '3:00 AM',
      hour: 3,
      minute: 0,
    },
  },
  {
    key: '3:30 AM',
    props: {
      id: '3:30 AM',
      hour: 3,
      minute: 30,
    },
  },
  {
    key: '4:00 AM',
    props: {
      id: '4:00 AM',
      hour: 4,
      minute: 0,
    },
  },
  {
    key: '4:30 AM',
    props: {
      id: '4:30 AM',
      hour: 4,
      minute: 30,
    },
  },
  {
    key: '5:00 AM',
    props: {
      id: '5:00 AM',
      hour: 5,
      minute: 0,
    },
  },
  {
    key: '5:30 AM',
    props: {
      id: '5:30 AM',
      hour: 5,
      minute: 30,
    },
  },
  {
    key: '6:00 AM',
    props: {
      id: '6:00 AM',
      hour: 6,
      minute: 0,
    },
  },
  {
    key: '6:30 AM',
    props: {
      id: '6:30 AM',
      hour: 6,
      minute: 30,
    },
  },
  {
    key: '7:00 AM',
    props: {
      id: '7:00 AM',
      hour: 7,
      minute: 0,
    },
  },
  {
    key: '7:30 AM',
    props: {
      id: '7:30 AM',
      hour: 7,
      minute: 30,
    },
  },
  {
    key: '8:00 AM',
    props: {
      id: '8:00 AM',
      hour: 8,
      minute: 0,
    },
  },
  {
    key: '8:30 AM',
    props: {
      id: '8:30 AM',
      hour: 8,
      minute: 30,
    },
  },
  {
    key: '9:00 AM',
    props: {
      id: '9:00 AM',
      hour: 9,
      minute: 0,
    },
  },
  {
    key: '9:30 AM',
    props: {
      id: '9:30 AM',
      hour: 9,
      minute: 30,
    },
  },
  {
    key: '10:00 AM',
    props: {
      id: '10:00 AM',
      hour: 10,
      minute: 0,
    },
  },
  {
    key: '10:30 AM',
    props: {
      id: '10:30 AM',
      hour: 10,
      minute: 30,
    },
  },
  {
    key: '11:00 AM',
    props: {
      id: '11:00 AM',
      hour: 11,
      minute: 0,
    },
  },
  {
    key: '11:30 AM',
    props: {
      id: '11:30 AM',
      hour: 11,
      minute: 30,
    },
  },
  {
    key: '12:00 Noon',
    props: {
      id: '12:00 Noon',
      hour: 12,
      minute: 0,
    },
  },
  {
    key: '12:30 PM',
    props: {
      id: '12:30 PM',
      hour: 12,
      minute: 30,
    },
  },
  {
    key: '1:00 PM',
    props: {
      id: '1:00 PM',
      hour: 13,
      minute: 0,
    },
  },
  {
    key: '1:30 PM',
    props: {
      id: '1:30 PM',
      hour: 13,
      minute: 30,
    },
  },
  {
    key: '2:00 PM',
    props: {
      id: '2:00 PM',
      hour: 14,
      minute: 0,
    },
  },
  {
    key: '2:30 PM',
    props: {
      id: '2:30 PM',
      hour: 14,
      minute: 30,
    },
  },
  {
    key: '3:00 PM',
    props: {
      id: '3:00 PM',
      hour: 15,
      minute: 0,
    },
  },
  {
    key: '3:30 PM',
    props: {
      id: '3:30 PM',
      hour: 15,
      minute: 30,
    },
  },
  {
    key: '4:00 PM',
    props: {
      id: '4:00 PM',
      hour: 16,
      minute: 0,
    },
  },
  {
    key: '4:30 PM',
    props: {
      id: '4:30 PM',
      hour: 16,
      minute: 30,
    },
  },
  {
    key: '5:00 PM',
    props: {
      id: '5:00 PM',
      hour: 17,
      minute: 0,
    },
  },
  {
    key: '5:30 PM',
    props: {
      id: '5:30 PM',
      hour: 17,
      minute: 30,
    },
  },
  {
    key: '6:00 PM',
    props: {
      id: '6:00 PM',
      hour: 18,
      minute: 0,
    },
  },
  {
    key: '6:30 PM',
    props: {
      id: '6:30 PM',
      hour: 18,
      minute: 30,
    },
  },
  {
    key: '7:00 PM',
    props: {
      id: '7:00 PM',
      hour: 19,
      minute: 0,
    },
  },
  {
    key: '7:30 PM',
    props: {
      id: '7:30 PM',
      hour: 19,
      minute: 30,
    },
  },
  {
    key: '8:00 PM',
    props: {
      id: '8:00 PM',
      hour: 20,
      minute: 0,
    },
  },
  {
    key: '8:30 PM',
    props: {
      id: '8:30 PM',
      hour: 20,
      minute: 30,
    },
  },
  {
    key: '9:00 PM',
    props: {
      id: '9:00 PM',
      hour: 21,
      minute: 0,
    },
  },
  {
    key: '9:30 PM',
    props: {
      id: '9:30 PM',
      hour: 21,
      minute: 30,
    },
  },
  {
    key: '10:00 PM',
    props: {
      id: '10:00 PM',
      hour: 22,
      minute: 0,
    },
  },
  {
    key: '10:30 PM',
    props: {
      id: '10:30 PM',
      hour: 22,
      minute: 30,
    },
  },
  {
    key: '11:00 PM',
    props: {
      id: '11:00 PM',
      hour: 23,
      minute: 0,
    },
  },
  {
    key: '11:30 PM',
    props: {
      id: '11:30 PM',
      hour: 23,
      minute: 30,
    },
  },
];

const debug = process.env.DEBUG === 'true';
const apiUrl = process.env.API_URL;
const clientId = process.env.GOOGLE_OAUTH_CLIENT_ID;
const cookieName = '562d0990-c123-4b91-8035-cfa655dce95b';

export {
  apiUrl,
  clientId,
  cookieName,
  debug,
  times,
};
