import React from 'react';
import {
  Card,
} from '@livechat/design-system'


interface EventTime {
  date?: string,
  dateTime?: string,
}

interface Event {
  id: string,
  summary?: string,
  start: EventTime,
  end: EventTime,
}

interface EventCardProps {
  event: Event,
}

function dateTimeStringsFromIso(startTime: string, endTime: string) {
  const startDate = new Date(startTime);
  const endDate = new Date(endTime);

  const localeDateOpts: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  const startDateString = startDate.toLocaleDateString([], localeDateOpts);
  const endDateString = endDate.toLocaleDateString([], localeDateOpts);

  const localeTimeOpts: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
  };
  const startTimeString = startDate.toLocaleTimeString([], localeTimeOpts);
  const endTimeString = endDate.toLocaleTimeString([], localeTimeOpts);

  return {
    'startDate': startDateString,
    'endDate': endDateString,
    'startTime': startTimeString,
    'endTime': endTimeString,
  }
}

function EventCard(props: EventCardProps) {
  const {
    event,
  } = props;

  let firstRow = '';
  let secondRow = '';
  if (event?.start?.date) {
    firstRow = 'All day';
  }
  if (event?.start?.dateTime && event?.end?.dateTime) {
    const dateTimeStrings = dateTimeStringsFromIso(event.start.dateTime, event.end.dateTime);

    const sameDate = dateTimeStrings.startDate === dateTimeStrings.endDate;
    firstRow = sameDate
      ? `${dateTimeStrings.startTime} - ${dateTimeStrings.endTime}`
      : `${dateTimeStrings.startDate} ${dateTimeStrings.startTime} -`;
    secondRow = sameDate
      ? ''
      : `${dateTimeStrings.endDate} ${dateTimeStrings.endTime}`;
  }

  return (
    <Card title={event.summary ? event.summary : '(No title)'}>
      <p>{firstRow}</p>
      <p>{secondRow}</p>
    </Card>
  );
}

export default EventCard;
