import React, { useEffect, useState } from 'react';
import {
  GoogleOAuthProvider,
} from '@react-oauth/google';
import {
  SectionComponent,
  SectionComponentType,
} from '@livechat/helpdesk-sdk';

import Calendar from 'components/Calendar';
import SignIn from 'components/SignIn';
import ViewContainer from 'components/ViewContainer';
import useDeveloperApp from 'hooks/app/useDeveloperApp';
import useHelpDeskDetailsWidget from 'hooks/products/helpdesk/useDetailsWidget';

import {
  apiUrl,
  clientId,
  cookieName,
  debug,
} from '../utils/const';
import {
  WidgetSectionLink,
} from '../utils/interfaces';

function getOpts(msg: string, variant: string, permanent = false) {
  const opts: any = {
    type: 'toast',
    payload: {
      variant: variant || 'error',
      content: msg,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      removable: true,
    }
  };

  if (!permanent) {
    opts.autoHideDelayTime = 5000;
  }

  return opts;
}

function setCookie(name: string, value: string) {
  const now = new Date();
  const time = now.getTime();
  const expireTime = time + 1000 * 3500;
  now.setTime(expireTime);
  const newCookie = `${name}=${value}; secure; samesite=None; path=/; expires=${now.toUTCString()}`;
  document.cookie = newCookie;
}

function HomePage(props: any) {
  const { notificationSystem } = props;
  const developerApp = useDeveloperApp();
  const { widget, ticketInfo } = useHelpDeskDetailsWidget();
  const [initLoadingCompleted, setInitLoadingCompleted] = useState(false);
  const [accessToken, setAccessToken] = useState('');
  const [organizationId, setOrganizationId] = useState('');
  const [credential, setCredential] = useState('');
  const initEventLinks: WidgetSectionLink[] = [];
  const [eventLinks, setEventLinks] = useState(initEventLinks);

  /**
   *
   * Check to see if we can use a recently logged in token
   *
   */
  useEffect(() => {
    const cookieCred = document.cookie
      .split('; ')
      .find((row) => row.startsWith(`${cookieName}=`))
      ?.split('=')[1];

    if (cookieCred) {
      setCredential(cookieCred);
    }
  }, []);

  /**
   *
   * Read the organization ID for HelpDesk from API
   *
   */
    useEffect(() => {
      if (!developerApp) return;

      if (debug) {
        console.log('Developer App:');
        console.log(developerApp);
      }

      // TODO I shouldn't need to re-authorize, this should be done on init; old developer SDK version?
      developerApp.authorize()
        .then(() => {
          if (developerApp?.authorization?.data?.access_token) {
            setAccessToken(developerApp.authorization.data.access_token);
            setOrganizationId(developerApp.authorization.data.organization_id);
          }
        })
        .catch((err) => {
          if (debug) {
            console.error('Error while authorizing.');
            console.error(err);
          }
        });
    }, [developerApp]);

  /**
   *
   * Read relevant ticket info
   * Read past calendar events which will trigger a widget section update
   *
   */
  useEffect(() => {
    if (!accessToken || !organizationId || !ticketInfo) return;

    if (debug) {
      console.log('Fetching past links with ticket Info');
      console.log(ticketInfo);
      console.log('developerApp');
      console.log(developerApp);
    }

    fetch(
      `${apiUrl}/v1/${organizationId}/ticket/${ticketInfo.id}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
      })
      .then((data) => {
        const eventLinks = data.links.map((entry: WidgetSectionLink) => {
          return {
            link: entry.link,
            name: entry.name
          }
        });
        setEventLinks(eventLinks);
      })
      .catch((err) => {
        if (debug) {
          console.error('Error fetching links');
          console.error(err);
        }
      })
      .finally(() => {
        setInitLoadingCompleted(true);
      });
  }, [accessToken, organizationId, ticketInfo]);

  /**
   *
   * Update the widget's section with calendar links
   *
   */
  useEffect(() => {
    // https://github.com/livechat/products-sdk/blob/master/packages/helpdesk-sdk/src/widgets/details/interfaces.ts
    if (debug) {
      console.log('Updating widget section with new event links');
      console.log(eventLinks);
    }

    if (widget && eventLinks.length) {
      const components: SectionComponent[] = eventLinks.map((link) => {
        return {
          type: SectionComponentType.Link,
          data: {
            url: link.link,
            value: link.name,
          },
        }
      });
      widget
        .modifySection({
          title: 'Google Calendar for LiveChat',
          components,
        })
        .catch((err) => {
          if (debug) {
            console.error('Error updating widget section');
            console.error(components);
            console.error(err);
          }
        });
    }
  }, [widget, eventLinks]);

  function addAlert(msg: string, variant: string, permanent = false) {
    notificationSystem.add(getOpts(msg, variant, permanent));
  }

  // This means that we need to ask the user sign in to Google
  if (clientId && !credential) {
    return (
      <GoogleOAuthProvider clientId={clientId}>
        <SignIn
          setCredential={(cred) => {
            setCookie(cookieName, cred);
            setCredential(cred);
          }}
          addAlert={addAlert}/>
      </GoogleOAuthProvider>
    );
  }

  // This means that we have loaded but don't have access to signin info
  if (initLoadingCompleted && (!accessToken || !organizationId)) {
    return (
      <ViewContainer>
        <h1>Could not sign in to HelpDesk. Please try again later or contact support.</h1>
      </ViewContainer>
    );
  }

  // This means we're firing on all cylinders
  if (initLoadingCompleted && accessToken && organizationId) {
    return (
      <Calendar
        accessToken={accessToken}
        addAlert={addAlert}
        credential={credential}
        eventLinks={eventLinks}
        organizationId={organizationId}
        setCookie={setCookie}
        setEventLinks={setEventLinks}
        ticketInfo={ticketInfo}
        widget={widget}
      />
    );
  }

  // This means the client ID was not loaded
  //   or something else more sinister happened
  return (
    <ViewContainer>
      <h1>An error occurred. Please contact support.</h1>
    </ViewContainer>
  );
}

export default HomePage
