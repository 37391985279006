import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import {
  Button,
  DatePicker,
  Form,
  InputField,
  SelectField,
  TextAreaField,
} from '@livechat/design-system';

import ViewContainer from './ViewContainer';
import EventCard from './EventCard';

import {
  apiUrl,
  cookieName,
  debug,
  times,
} from '../utils/const';
import {
  CalendarProps,
  CalendarItemShape,
  TimeItemShape,
  GCalAttendee,
  WidgetSectionLink,
} from '../utils/interfaces';

function Calendar(props: CalendarProps) {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const initCal: any[] = [];
  const [calendars, setCalendars] = useState(initCal);
  const initSelectedCal: CalendarItemShape = {
    key: '',
    props: {
      id: '',
      value: '',
    },
  };
  const [selectedCalendar, setSelectedCalendar] = useState(initSelectedCal);
  const initEvents: any[] = [];
  const [events, setEvents] = useState(initEvents);
  const initSelectedTime: TimeItemShape = {
    key: '',
    props: {
      id: '',
      hour: -1,
      minute: -1,
    },
  };
  const [selectedStartTime, setSelectedStartTime] = useState(initSelectedTime);
  const [selectedEndTime, setSelectedEndTime] = useState(initSelectedTime);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [guestEmails, setGuestEmails] = useState('');

  const router = useRouter();

  const {
    accessToken,
    addAlert,
    credential,
    eventLinks,
    organizationId,
    setCookie,
    setEventLinks,
    ticketInfo,
    widget,
  } = props;

  /**
   *
   * Init, read all of a user's writeable calendars
   *
   */
  useEffect(() => {
    readAllCalendars();
  }, []);

  /**
   *
   * Read events after a calendar has been selected/on init
   *
   */
  useEffect(() => {
    if (!selectedCalendar.key) return;

    readAllEvents();
  }, [selectedCalendar, selectedDate]);

  /**
   *
   * Read relevant ticket info
   * Populate guest emails field with ticket requester
   * Read past calendar events which will trigger a widget section update
   *
   */
  useEffect(() => {
    if (debug) {
      console.log('Updating guest emails with ticket Info');
      console.log(ticketInfo);
    }

    if (!ticketInfo) return;

    const reqer = ticketInfo.requester.email;
    if (guestEmails.length && !guestEmails.includes(reqer)) {
      setGuestEmails(`${guestEmails},${reqer}`);
    } else {
      setGuestEmails(reqer);
    }
  }, [ticketInfo]);

  /**
   *
   * Read all of a user's calendars
   * They might have multiple (work, personal, etc.)
   * Allow them to create an event on any that they have write access to
   *
   */
  async function readAllCalendars() {
    let nextPageToken = null;
    const calBuffer: any[] = [];
    do {
      const parameters: any = {
        minAccessRole: 'writer',
      };
      if (nextPageToken) {
        parameters.pageToken = nextPageToken;
      }
      const calListRes = await fetch(
        `https://www.googleapis.com/calendar/v3/users/me/calendarList?${new URLSearchParams(parameters)}`,
        {
          headers: {
            Authorization: `Bearer ${credential}`,
          },
        },
      );


      if (!calListRes.ok) {
        if (calListRes.status === 401) {
          addAlert('Your credentials have expired please refresh the page.', 'error', true);
          setCookie(cookieName, '');
          return;
        } else {
          addAlert('Error reading calendar information. If this persistes, contact support.', 'error', false);
          return;
        }
      }

      const calList = await calListRes.json();

      if (debug) {
        console.log(calList.items);
      }

      calBuffer.push(...calList.items);
      if (calList.nextPageToken) {
        nextPageToken = calList.nextPageToken;
      } else {
        nextPageToken = null;
      }
    } while (nextPageToken);

    const shapedCals = calBuffer.map((cal) => {
      return {
        key: cal.id,
        props: {
          id: cal.id,
          value: cal.summary,
        },
      };
    });

    setCalendars(shapedCals);
    if (shapedCals.length) {
      setSelectedCalendar(shapedCals[0]);
    }
  }

  /**
   *
   * Read all events on a calendar the selected day
   *
   */
  async function readAllEvents() {
    let nextPageToken = null;
    const eventsBuffer: any[] = [];
    do {
      // Set date to midnight this morning
      selectedDate.setHours(0, 0, 0, 0);

      // Get tomorrow (tonight?) at midnight
      const tomorrow = new Date(selectedDate.valueOf());
      tomorrow.setDate(tomorrow.getDate() + 1);

      const parameters: any = {
        // orderBy: 'startTime',
        timeMin: selectedDate.toISOString(),
        timeMax: tomorrow.toISOString(),
      };
      if (nextPageToken) {
        parameters.pageToken = nextPageToken;
      }
      const eventsListRes = await fetch(
        `https://www.googleapis.com/calendar/v3/calendars/${selectedCalendar.key}/events?${new URLSearchParams(parameters)}`,
        {
          headers: {
            Authorization: `Bearer ${credential}`,
          },
        }
      );

      if (!eventsListRes.ok) {
        if (eventsListRes.status === 401) {
          addAlert('Your credentials have expired please refresh the page.', 'error', true);
          setCookie(cookieName, '');
          return;
        } else {
          addAlert('Error reading events. If this persists, contact support.', 'error', false);
          return;
        }
      }


      const eventsList = await eventsListRes.json();

      if (debug) {
        console.log(eventsList.items);
      }

      eventsBuffer.push(...eventsList.items);
      if (eventsList.nextPageToken) {
        nextPageToken = eventsList.nextPageToken;
      } else {
        nextPageToken = null;
      }
    } while (nextPageToken);

    setEvents(eventsBuffer);
  }

  /**
   *
   * Create an event given all the data entered on screen
   *
   */
  async function createEvent(e: any) {
    e.preventDefault();

    if (selectedStartTime.props.hour < 0) {
      addAlert('Please select a start time.', 'error', false);
      return;
    }

    if (selectedEndTime.props.hour < 0) {
      addAlert('Please select an end time.', 'error', false);
      return;
    }

    const startDate = new Date(selectedDate.valueOf());
    startDate.setHours(selectedStartTime.props.hour, selectedStartTime.props.minute, 0, 0);

    const endDate = new Date(selectedDate.valueOf());
    endDate.setHours(selectedEndTime.props.hour, selectedEndTime.props.minute, 0, 0);

    const attendees: GCalAttendee[] = [];
    guestEmails?.split(',')?.forEach((email) => {
      if (email) {
        attendees.push({
          email,
          responseStatus: 'needsAction',
        });
      }
    });

    const body = {
      summary: title,
      description: description,
      attendees: attendees,
      start: {
        dateTime: startDate.toISOString(),
      },
      end: {
        dateTime: endDate.toISOString(),
      },
    };

    try {
      const createEventRes = await fetch(
        `https://www.googleapis.com/calendar/v3/calendars/${selectedCalendar.key}/events`,
        {
          headers: {
            Authorization: `Bearer ${credential}`,
          },
          method: 'POST',
          body: JSON.stringify(body),
        },
      );

      if (createEventRes.ok) {
        // add link to message box
        const createEvent = await createEventRes.json();

        if (debug) {
          console.log('Successfully created Google Calendar event.');
          console.log(createEvent);
        }

        const newEventLinks = new Array(...eventLinks);
        const newLinkEntry: WidgetSectionLink = {
          link: createEvent.htmlLink,
          name: title || '(No title)', // This is how GCal would title it
        };
        newEventLinks.push(newLinkEntry);
        setEventLinks(newEventLinks);

        if (ticketInfo) {
          // By now ticketInfo should be available anyway
          fetch(
            `${apiUrl}/v1/${organizationId}/ticket/${ticketInfo.id}`,
            {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`,
              },
              body: JSON.stringify(newLinkEntry),
            },
          )
            .then((res) => {
              if (debug && res.ok) {
                console.log('Link added to API');
              }
            })
            .catch((err) => {
              if (debug) {
                console.error('Error adding link to API');
                console.error(err);
              }
            });
        }

        // TODO need some way to give the user the link immediately; I want to append it in the message box
        // if (widget) {
        //   // This is a little hacky and I had to search through source on how to do it
        //   // https://github.com/livechat/products-sdk/blob/0ccbf18b799329b4ad93235468ea211630b34837/packages/agent-app-sdk/src/widgets/details/details-widget.ts#L21
        //   widget.sendMessage(
        //     'put_message',
        //     {
        //       type: 'message',
        //       value: createEvent.htmlLink,
        //     }
        //   )
        //     .then((...idk) => {
        //       if (debug) {
        //         console.log('Widget send message fulfilled');
        //         console.log(...idk);
        //       }
        //     })
        //     .catch((err) => {
        //       if (debug) {
        //         console.error('Error while sending message.');
        //         console.error(err);
        //       }
        //     });
        // }

        navigator.clipboard.writeText(createEvent.htmlLink)
          .then(() => {
            if (debug) {
              console.log(`Event link copied to clipboard. ${createEvent.htmlLink}`);
            }

            addAlert('Event link copied to clipboard.', 'success', false);
          })
          .catch((err) => {
            if (debug) {
              console.error('Error copying to clipboard.');
              console.error(err);
            }

            addAlert('Event has been created.', 'success', false);
          });
        return;
      } else if (createEventRes.status === 400) {
        const createEvent = await createEventRes.json();
        if (createEvent.error.errors.length) {
          if (createEvent.error.errors[0].reason === 'timeRangeEmpty') {
            addAlert('Something was wrong with your request. Make sure the end time is after the start time.', 'error', false);
            return;
          }
        }

        addAlert('Something was wrong with your request.', 'error', false);
      } else if (createEventRes.status === 401) {
        addAlert('Your credentials have expired please refresh the page.', 'error', true);
        setCookie(cookieName, '');
        return;
      } else {
        // otherwise inform user of error
        if (debug) {
          console.error('Error status');
          console.error(createEventRes.status);
        }

        addAlert('Error creating event. If this persists, contact support.', 'error', false);
      }
    } catch(err) {
      console.error(err);
    }
  }

  // Selector props for calendars
  const handleCalendarSelect = (calendarKey: string) => {
    const selectedCal = calendars.find((cal) => cal.key === calendarKey);
    setSelectedCalendar(selectedCal);
  }

  const getCalendarItemBody = (props: any) => {
    if (!props) {
      return null;
    }
    return <div id={props.id}>{props.value}</div>;
  };

  const getCalendarSelectedItemBody = (props: any) => {
    return <div id={props.id}>{props.value}</div>;
  };

  // Selector props for times
  const handleStartTimeSelect = (timeKey: string) => {
    const selectedTime = times.find((time) => time.key === timeKey);
    if (selectedTime) {
      setSelectedStartTime(selectedTime);
    }
  }

  const handleEndTimeSelect = (timeKey: string) => {
    const selectedTime = times.find((time) => time.key === timeKey);
    if (selectedTime) {
      setSelectedEndTime(selectedTime);
    }
  }

  const getTimeItemBody = (props: any) => {
    if (!props) {
      return null;
    }
    return <div id={props.id}>{props.id}</div>;
  };

  const getTimeSelectedItemBody = (props: any) => {
    return <div id={props.id}>{props.id}</div>;
  };

  return (
    <ViewContainer>
      <SelectField
        id="select-calendar"
        labelText="Calendar"
        items={calendars}
        searchProperty="value"
        onItemSelect={handleCalendarSelect}
        getItemBody={getCalendarItemBody}
        search
        placeholder={selectedCalendar?.props?.value || "Select calendar"}
        getSelectedItemBody={getCalendarSelectedItemBody}
        selected={selectedCalendar?.key}
        searchPlaceholder="Search..."
      />

      <DatePicker
        onDayClick={setSelectedDate}
        selectedDays={selectedDate}/>

        <h3 className="lc-card__text">Selected Day&apos;s Events</h3>
        {
          events.length
          ? (
            (
              <div
                style={{
                  maxHeight: '300px',
                  overflow: "auto",
                }}
              >
                {
                  events.map((event) => {
                    if (event.start) {
                      // We at least need some sort of timing information otherwise there's no value
                      // Problem first seen for a recurring yearly event
                      return (
                        <EventCard key={event.id} event={event} />
                      );
                    }
                  })
                }
              </div>
            )
          )
          : (
            <p className="lc-text-field__label">No events scheduled for this day</p>
          )
        }

      <Form
        labelText='New Event Details'
        onSubmit={createEvent}
        formFooter={<Button kind="primary" submit>Create event</Button>}
        style={{
          marginTop: '16px',
        }}
      >
        <SelectField
          id="select-start-time"
          labelText="Start Time"
          items={times}
          searchProperty="id"
          onItemSelect={handleStartTimeSelect}
          getItemBody={getTimeItemBody}
          placeholder={"Select start time"}
          getSelectedItemBody={getTimeSelectedItemBody}
          selected={selectedStartTime?.key || "Select start time"}
          searchPlaceholder="Search..."
        />
        <SelectField
          id="select-end-time"
          labelText="End Time"
          items={times}
          searchProperty="id"
          onItemSelect={handleEndTimeSelect}
          getItemBody={getTimeItemBody}
          placeholder={"Select end time"}
          getSelectedItemBody={getTimeSelectedItemBody}
          selected={selectedEndTime?.key || "Select end time"}
          searchPlaceholder="Search..."
        />

        <InputField
          value={title}
          labelText='Title'
          id='title-input-field'
          placeholder='Event Title'
          onChange={(e) => setTitle(e.target.value)}
        />
        <TextAreaField
          value={description}
          labelText='Description'
          id='description-input-field'
          placeholder='Event Description'
          onChange={(e) => setDescription(e.target.value)}
        />
        <InputField
          value={guestEmails}
          labelText='Guests'
          id='guests-input-field'
          placeholder='Emails'
          description='Separate emails with a comma i.e. invite1@email.com,invite2@email.com'
          onChange={(e) => setGuestEmails(e.target.value)}
        />
      </Form>
    </ViewContainer>
  );
}

export default Calendar;
